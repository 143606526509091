@font-face {
    font-family: 'Arial';
    src: url('../fonts/ArialMT.eot');
    src: url('../fonts/ArialMT.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ArialMT.woff2') format('woff2'),
        url('../fonts/ArialMT.woff') format('woff'),
        url('../fonts/ArialMT.ttf') format('truetype'),
        url('../fonts/ArialMT.svg#ArialMT') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html {
	scroll-behavior: smooth;
  }


ul {
    padding: 0;
    margin: 0;
    li {
        list-style: none;
    }
}
a {
    transition: all .5s;
    text-decoration: none;
    font-size: 25px;
}
h1 {
    font-size: 162px;
    a {
        text-decoration: none;
        color: inherit;
    }
}

h2 {
    

    a {
        text-decoration: none;
        color: inherit;
    }
}

h3 {
    font-size: 115px;
    a {
        text-decoration: none;
        color: inherit;
    }
}

h3.small{
	font-size: 83px;
}

h4 {
    font-size: 69px;
    a {
        text-decoration: none;
        color: inherit;
    }
}
h4.small {
    font-size: 53px;
    a {
        text-decoration: none;
        color: inherit;
    }
}

h5 {
    font-size: 46px;
    a {
        text-decoration: none;
        color: inherit;
    }
}
h5.small{
    font-size: 37px;
    a {
        text-decoration: none;
        color: inherit;
    }
}

h6{
	font-size: 36px;
}

h6.small {
    font-size: 34px;
    a {
        text-decoration: none;
        color: inherit;
    }
}



p{
	font-size: 12px;
}

.btn{
	font-size: 27px;

}

/***************************************************************************************************************/


header{
	padding: 15px 33px 12px 23px;
	background-color: $white;
	.heder_wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.logo {
			max-width: 230px;
			width: 100%;
			transition: all .5s ease-in-out;

			img{
				width: 100%;
			}
		}

		.navbar{
			.main_menu{
				.menu_logo{
					display: none;
				}
				ul{
					position: relative;
					li{
    					padding: 10px 12.5px;
						position: relative;
						a{
							transition: all .5s ease-in-out;
							font-size: 25px;
							color: $Roman_Silver;
							font-family: $primary_font;
							text-transform: uppercase;
							font-weight: 400;
							&:hover{
								color: $Cobalt_Blue;
							}
						}

						.mega_nav_container{
							display: none;
							transition: all 0.5s ease-in-out;
							ul{
								position: absolute;
								left: 0;
								width: 150px;
								height: auto;
								background: #fff;
								z-index: 1;
								padding: 20px 10px 10px 20px;
								box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
								top: 50px;

								li{
									padding: 0 0 10px 0;
    								text-align: left;
								}
							}
						}
						.toggle_dropdown{
							display: none;
							position: absolute;
							right: 15px;
							top: 15px;
							width: 15px;
							height: 15px;
						}

						&:hover{
							.mega_nav_container{
								display: block;
							}
						}
					}
				}
			}
			.close{
				display: none;
			}
		}

		.social_details{
			.wrap {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.tell_emial{
					p{
						font-size: 25px;
						color: $Roman_Silver;
						font-family: $primary_font;
						line-height: 1.2;
						margin-bottom: 0;
						font-weight: 500;
						a{
							font-size: 25px;
							color: $Roman_Silver;
							font-family: $primary_font;
							line-height: 1;
							font-weight: 500;
							&:hover{
								color: $Cobalt_Blue;
							}
							span{
								display:none;
							}
						}
					}
				}
				.link{
    				padding-left: 70px;

					ul{
						margin-top: -5px;
						li{
							height: 20px;
							line-height: 1;
							margin-bottom: 10px;
							a{
								display: block;
    							line-height: 1;
								&:hover{
									color: $Cobalt_Blue;
								}
								img{
									width: 20px;
									height: 20px;
								}
							}
						}
					}
				}
			}
		}

		.hamburger-icon{
			display: none;
		}
	}
}

header.fixed-is-active {
    position: fixed;
    z-index: 5;
    width: 100%;
    transition: all .5s ease-in-out;
    box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;

	.logo {
		max-width: 180px;
		width: 100%;
		transition: all 0.5s ease-in-out;
	}
	.navbar {
		.main_menu {
			ul{
				li{
					a {
						font-size: 23px;
					}
				}
			}	
		}
	}
}