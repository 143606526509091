

/* START :: Custom variable */
html {
    scroll-behavior: smooth;
}

body {
  font-size: 18px;
}
/* START :: Bootstrap variable  */
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

/**** colour variable ******/
$Oxford_Blue: #0C2340;
$Independence:#495A70; 
$Alice_Blue: #EEF4FC;
$Halloween_Orange: #F15A22;
$Sinopia:#D3430D;
$Eerie_Black: #1A1A1A;
$light_pink:#000;
$white:#ffffff; 
$title_border:#828282;
$input_border: #DBDEE2;
$Dark_liver:#4F4F4F;

/***********************************************/
$Roman_Silver: #838D8E;
$Cobalt_Blue: #0947A4;
$footer:#8b898c;




/****** font family ******/
$primary_font: 'Barlow', sans-serif;
$secondary_font: 'Rajdhani', sans-serif;
$arial: 'Arial', sans-serif;


/*END :: Bootstrap variable*/


/**** customer class ****/
.black_background{
  background-color:$Eerie_Black;
}

#wrapper {
  overflow: hidden;
}

/*** slick slider css *****/
button.slick-prev.slick-arrow {
  font-size: 0;
  position: absolute;
  left: -90px;
  top: 25%;
  transform: translateY(-50%);
  border: none;
  width: 30px;
  height: 30px;
  background-color: transparent;

  &:before{
    content: ' ';
    left: 0;
    top: 2px;
    width: 30px;
    height: 30px;
    background-image: url('../images/left_arrow.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
  }
}
button.slick-next.slick-arrow{
  font-size: 0;
  position: absolute;
  right: -90px;
  top: 25%;
  transform: translateY(-50%);
  border: none;
  width: 30px;
  height: 30px;
  background-color: transparent;

  &:before{
    content: " ";
    left: 0;
    top: 2px;
    width: 30px;
    height: 30px;
    background-image: url('../images/right_arrow.png'); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
  }
}

