
.hero_section{
    position: relative;
    height: 840px;
    overflow: hidden;

    .hero_slide{
        position: relative;
        &:before{
            content: ' ';
            position: absolute;
            left: 0;
            width: 100%;
            height: 555px;
            top: 212px;
            background-image: url('../images/hero_background_line.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            opacity: 0.6;
        }
    }

    .background_img {
        img{
            width: 100%;
            height: 840px;
            object-position: top;
            object-fit: fill;
        }
    }

    .content{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);

        .inner_wrap{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .left_circle{
                background: $Cobalt_Blue;
                border-radius: 50%;
                width: 580px;
                height: 580px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                border: 20px solid $white;

                .wrap{
                    h3{
                        font-size: 80px;
                        color: $white;
                        font-family: $secondary_font;
                    }
                }
            }

            .right_circle{
                .wrap{
                    a{
                        background: #838D8E;
                        border-radius: 50%;
                        width: 160px;
                        height: 160px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        border: 15px solid #ffffff;
                        padding: 10px;
                        h6.small{
                            color: $white;
                            font-family: $primary_font;
                            font-size: 18px;
                            margin: 0;
                        }
                    }
                }
            }

        }
    }
}


.about-us{
    position: relative;
    padding: 80px 0 0 0;

    &:before{
        content: ' ';
        position: absolute;
        left: 0;
        width: 54%;
        top: 0;
        height: 355px;
        background-image: url('../images/about_left.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: -1;
   }
   &:after{
        content: " ";
        position: absolute;
        right: 0;
        width: 90%;
        top: 93px;
        height: 507px;
        background-image: url(../images/about_right.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: -2;
    }



    .about_title{
        height: 100%;
        position: relative;
        .wrap{
            h1{
                font-size: 80px;
                color: #ffffff;
                text-align: right;
                padding: 0 115px 0 0;
                text-transform: uppercase;
                line-height: 1;
                margin: 0;
                span{
                    font-size: 45px;
                    text-transform: capitalize;
                }
            }
            a{
                position: absolute;
                bottom: 70px;
                right: 115px;
                border: 1px solid #fff;
                padding: 10px 20px;
                color: #fff;
                font-size: 20px;
                text-transform: uppercase;

                &:hover{
                    background-color: $white;
                    color: $Cobalt_Blue;
                }
            }
        }
    }
    .content_wrap{
        padding: 60px 0 30px 0;
       p{
        color: #ffffff;
        font-size: 22px;
        text-align: right;
        margin-bottom: 50px;
        padding: 0 0 0 265px;
        font-weight: 300;
       }
    }
}

.what_we_do{
    padding:80px 0;
    .wrap{
        h5{
            text-align: center;
            color: #838D8E;
            font-weight: 300;
            margin-bottom: 50px;
            letter-spacing: 1px;
        }

        ul{
            justify-content: space-between;
            li{
                text-align: center;
                span{
                    width: 100px;
                    height: 100px;
                    background: $Cobalt_Blue;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    padding: 10px; 
                    margin: 0 auto;
                    margin-bottom: 10px;
                    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
                    img{
                        width: 100%;
                    }
                }
                
                p{
                    font-weight: 400;
                    color: $Roman_Silver;
                    font-family: $primary_font;
                    font-size: 40px;
                    font-size: 19px;
                }
            }
        }
    }
}


.our_product{
    position: relative;
    padding: 0 0 80px 0;
    &:before{
        content: ' ';
        position: absolute;
        left: 0;
        width: 54%;
        top: 62px;
        height: 355px;
        background-image: url('../images/get_in_touch_background_left.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: -1;
   }
   &:after{
        content: ' ';
        position: absolute;
        right: 0;
        width: 64.6%;
        top: 0;
        height: 417px;
        background-image: url('../images/our_product.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: -2;
    }
    .triangle {
        width: 0;
        height: 0;
        border-top: 63px solid $white;
        border-right: 80px solid transparent;
        position: absolute;
        left: 35.35%;
    }

   .entry_title{
        padding: 155px 0 80px 0;
        &:before{
            content: " ";
            position: absolute;
            left: 50%;
            width: 24%;
            top: 0;
            height: 417px;
            background-image: url(../images/getintouchlightblack.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            z-index: -1;
            transform: translate(-50%);
        }

        h3.small{
            color: $white;
            font-size: 65px;
            span{
                font-size: 80px;
                text-transform: uppercase;
            }
        }
   }

   .button{
        text-align: center;
        a{
            text-transform: uppercase;
            border: 1px solid $Roman_Silver;
            padding: 15px 20px;
            color: $Roman_Silver;

            &:hover{
                background-color: $Cobalt_Blue;
                color: $white;
                border-color: $Cobalt_Blue;
            }
        }
   }


   .our_product_wrap{
        max-width: 80%;
        width: 100%;
        margin: 0 auto;
        padding-top: 60px;
        margin-bottom: 100px;

        img{
            width: 100%;
        }

        .content{
            padding: 35px 30px;
            position: relative;
            border: 1px solid $Roman_Silver;

            h5{
                a{
                    color: $Roman_Silver;
                    font-size: 29px;
                }
            }
            p{
                color: $Roman_Silver;
                font-size: 22px;
                margin: 0;
            }
            .find_out{
                position: absolute;
                right: -55px;
                text-align: center;
                border-radius: 50%;
                border: 18px solid #838d8e;
                width: 110px;
                height: 110px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff;
                bottom: 20px;
                transition: all 0.5s;

                &:before{
                    content: " ";
                    left: -45px;
                    top: 20px;
                    width: 30px;
                    height: 30px;
                    background-image: url(../images/left_arrow.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    position: absolute;
                }

                a{
                    text-align: center;
                    font-size: 13px;
                    color: $Roman_Silver;
                }
            }
        }
   }

}

/****************************************/

.latest_news{
    position: relative;
    padding-bottom: 60px;
    
    &:before{
        content: ' ';
        position: absolute;
        left: 0;
        width: 64%;
        top: 0;
        height: 420px;
        background-image: url('../images/latest_news_left.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: -2;
   }
   &:after{
        content: ' ';
        position: absolute;
        right: 0;
        width: 55.5%;
        top: 65px;
        height: 355px;
        background-image: url('../images/latest_news.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: -1;
    }

    .triangle{
        width: 0;
        height: 0;
        border-top: 70px solid $white;
        border-left: 110px solid transparent;
        position: absolute;
        right: 36%;
        z-index: -1;
    }

    .entry_title{
        padding: 145px 0 103px 0;

        &:before{
            content: " ";
            position: absolute;
            left: 50%;
            width: 24.5%;
            top: 0;
            height: 420px;
            background-image: url(../images/latest_news_background.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            z-index: 1;
            transform: translate(-50%);
        }

        h3.small{
            color: $white;
            font-size: 65px;
            text-align: right;
            span{
                font-size: 80px;
                text-transform: uppercase;
            }
        }
   }

   /*****************************/
   .news_slider{
        .latest_news_slider{
            .wrap{
                max-width: 80%;
                width: 100%;
                margin: 0 auto;
                padding-top: 60px;
                img{
                    width: 100%;
                    margin-bottom: 40px;
                }
                h5.small{
                    color: $Roman_Silver;
                    font-weight: 300;
                    margin-bottom: 20px;
                    font-family: $primary_font;
                }
                p{
                    font-family: $primary_font;
                    color: $Roman_Silver;
                    font-size: 29px;
                    margin-bottom: 20px;
                    padding: 0 40px 0 0;
                    font-weight: 300;
                }
                a{
                    color: $Roman_Silver;
                    font-size: 25px;
                    margin-bottom: 10px;
                    font-family: $primary_font;
                    display: inline-block;
                    transition: all 0.5s;
                    span{
                        padding-left: 10px;
                        transition: all 0.5s;
                        i{
                            font-size: 20px;
                        }
                    }
                    &:hover{
                        span{
                            padding-left: 15px;
                        }
                    }
                }
                p.date{
                    color: $Roman_Silver;
                    font-size: 20px;
                    margin-bottom: 0;
                    font-family: $primary_font;
                }
            }
        }
   }

}

.accreditations{
    padding: 60px 0;
    border-top: 1px solid $Roman_Silver;
    .wrap{
        h5{
            text-align: center;
            color: $Roman_Silver;
            font-weight: 300;
            margin-bottom: 50px;
            letter-spacing: 1px;
        }
        .accreditations_slide{
            .slick-slide{
                a{
                    margin: 0 10px;
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }

    button.slick-next.slick-arrow{
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
        height: 25px;

        &:before{
            width: 25px;
            height: 25px;
        }
    }
    button.slick-prev.slick-arrow{
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
        height: 25px;

        &:before{
            width: 25px;
            height: 25px;
        }
    }

}

    .get_in_touch{
    position: relative;
    padding: 155px 0 80px 0;
    .triangle {
        width: 0;
        height: 0;
        border-top: 65px solid $white;
        border-right: 100px solid transparent;
        position: absolute;
        top: 0;
        left: 35.4%;
    }
    
   &:before{
        content: ' ';
        position: absolute;
        left: 0;
        width: 54%;
        bottom: 0;
        height: 355px;
        background-image: url('../images/get_in_touch_background_left.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: -1;
   }
   &:after{
        content: ' ';
        position: absolute;
        right: 0;
        width: 64.6%;
        bottom: 0;
        height: 100%;
        background-image: url('../images/get_in_touch.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: -2;
    }

   .entry_title{


        &:before{
            content: " ";
            position: absolute;
            left: 50%;
            width: 24%;
            bottom: 0;
            height: 100%;
            background-image: url(../images/getintouchlightblack.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            z-index: -1;
            transform: translate(-50%);
        }

        h3.small{
            color: $white;
            font-size: 65px;
            span{
                font-size: 80px;
                text-transform: uppercase;
            }
        }
   }

}