@media screen and (min-width: 1920px) {
    .hero_section{
        &:before{
            height:620px;
            top: 145px;
        }
    }
    .about-us{
        &:before{
            height: 375px;
        }
    }
}

@media screen and (min-width: 2400px) {
    .hero_section{
        &:before{
            height:680px;
            top: 75px;
        }
    }
    .about-us{
        &:before{
            height:402px;
        }
    }
}
@media screen and (min-width: 2560px) {
    .hero_section{
        &:before{
            height:750px;
            top: 20px;
        }
    }
    .about-us{
        &:before{
            height:417px;
        }
    }
}
@media screen and (min-width: 2880px) {
    .hero_section{
        &:before{
            height:815px;
            top: 0;
        }
    }
    .about-us{
        &:before{
            height: 459px;
            width: 56%;
        }
    }
}
@media screen and (max-width: 1890px) {
    .about-us{
        &:after{
            top: 84px;
            height: 516px;
        }
    }
}
@media screen and (max-width: 1820px) {
    .our_product{
        &:before{
            width: 54.5%;
        }
    }
}
@media screen and (max-width: 1780px) {
    header {
        .heder_wrap {
            .logo {
                max-width: 220px;
            }
            .navbar {
                .main_menu {
                    ul {
                        li {
                            a {
                                font-size: 23px;
                            }
                        }
                    }
                }
            }
            .social_details {
                .wrap {
                    .tell_emial {
                        p {
                            font-size: 23px;
                            a{
                                font-size: 23px;
                            }
                        }
                    }
                }
            }
        }
    }

    .hero_section{
        .content {
            .inner_wrap {
                .left_circle {
                    width: 560px;
                    height: 560px;
                    .wrap {
                        h3{
                            font-size: 77px;
                        }
                    }
                }
            }
        }
    }


    .about-us{
        &:after{
            top: 89px;
            height: 511px;
        }
    }

    .latest_news {
        .triangle{
            right: 35.5%; 
        }
    }



}

@media screen and (max-width: 1760px) {
    .our_product{
        &:before{
            width: 55%;
        }
    }
}

@media screen and (max-width: 1715px) {
    .latest_news {
        .triangle{
            right: 34%;
            border-top: 93px solid #ffffff;
        }
    }
    .get_in_touch{
        &:before{
            width: 55%;
        }
    }
}

@media screen and (max-width: 1680px) {
    .hero_section{
        &:before{
            height: 500px;
        }
        .content {
            .inner_wrap {
                .left_circle {
                    .wrap {
                        h3{
                            font-size: 71px;
                        }
                    }
                }
            }
        }
    }

    .about-us{
        &:after{
            top: 94px;
            height: 505px;
        }
    }


    .our_product {
        &:before{
            width: 56%;
        }
        .entry_title{
            &:before{
                width: 29%;
                background-size: contain;
            }
        }
    }
    

    .latest_news {
        .entry_title{
            &:before{
                width: 29.5%;
                background-size: contain;
            }
        }
    }

    .get_in_touch {
        .entry_title{
            &:before{
                background-size: contain;
                width: 29%;
            }
        }
    }


}

@media screen and (max-width: 1680px) {
    header {
        .heder_wrap {
            .navbar {
                .main_menu{
                    ul {
                        li{
                            a {
                                font-size: 22px;
                            }
                        }
                    }
                }
            }
            .social_details {
                .wrap{
                    .tell_emial {
                        p {
                            font-size: 21px;
                            a{
                                font-size: 21px;
                            }
                        }
                    }
                }
            }
        }
    }

    .hero_section {
        .content {
            .inner_wrap {
                .left_circle {
                    width: 530px;
                    height: 530px;
                    .wrap {
                        h3{
                            font-size: 68px;
                        }
                    }
                }
            }
        }
    }

    .about-us{
        &:after {
            top: 99px;
            height: 501px;
        }
        .about_title {
            .wrap{
                h1{
                    padding: 0 95px 0 0;
                }
                a{
                    font-size: 19px;
                    padding: 8px 15px;
                    right: 145px;
                }
            }
        }
    }

    .our_product {
        .our_product_wrap {
            .content{
                h5{
                    a{
                        font-size: 28px;
                    }
                }
            }
        }
        .entry_title{
            &:before {
                width: 31%;
            }
        }
    }

    .latest_news{
        .news_slider {
            .latest_news_slider {
                .wrap {
                    p{
                        font-size: 28px;
                    }
                    a{
                        font-size: 24px;
                    }
                }
            }
        }
        .entry_title{
            &:before {
                width: 31%;
            }
        }
    }

    .get_in_touch{
        .entry_title{
            &:before {
                width: 31%;
            }
        }
    }
    
    

}

@media screen and (max-width: 1635px) {
    .latest_news{
        &:before{
            width: 65%;
        }
        .triangle {
            right: 33%;
        }
    }
    .get_in_touch{
        &:before{
            width: 56%;
        }
        .triangle {
            border-top: 62px solid #ffffff;
        }
    }
}

@media screen and (max-width: 1550px) {
    .our_product {
        &:before{
            width: 57%;
        }
    }
}


@media screen and (max-width: 1500px) {
    header {
        .heder_wrap {
            .logo {
                max-width: 195px;
            }
            .navbar{
                .main_menu {
                    ul{
                        li {
                            a {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
            .social_details {
                .wrap {
                    .tell_emial {
                        p {
                            font-size: 20px;
                            a {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }


    .hero_section {
        .content {
            .inner_wrap {
                .left_circle {
                    width: 480px;
                    height: 480px;
                    .wrap {
                        h3{
                            font-size: 60px;  
                        }
                    }
                }
            }
        }
    }


    .about-us{
        &:after {
            top: 103px;
            height: 497px;
        }
        .content_wrap{
            p{
                padding: 0 0 0 257px;
            }
        }
    }


}

@media screen and (max-width: 1480px) {
    button.slick-prev.slick-arrow{
        left: -35px;
    }
    button.slick-next.slick-arrow{
        right: -35px;
    }
    .latest_news{
        &:before {
            width: 66%;
        }
        .triangle {
            right: 32%;
        }
    }

    .get_in_touch{
        &:before{
            width: 57%;
        }
        .triangle {
            border-top: 62px solid #ffffff;
        }
    }
}

@media screen and (max-width: 1460px) {
    .our_product {
        &:before{
            width: 58%;
        }
    }
}



@media screen and (max-width: 1440px) {
    header {
        .heder_wrap{
            .logo {
                max-width: 180px;
            }
            .navbar {
                .main_menu{
                    ul {
                        li {
                            a {
                                font-size: 19px;
                            }
                        }
                    }
                }
            }
            .social_details {
                .wrap{
                    .tell_emial{
                        p {
                            font-size: 19px;
                            a {
                                font-size: 19px;
                            }
                        }
                    }
                }
            }
            
        }
    }

    .hero_section{
        &:before {
            height: 525px;
        }
        .content {
            .inner_wrap {
                .left_circle {
                    width: 420px;
                    height: 420px;
                    .wrap {
                        h3{
                            font-size: 53px;
                        }
                    }
                }
            }
        }
    }

    .about-us{
        &:before{
            background-size: contain;
        }
        &:after{
            top: 108px;
            height: 439px;
        }
        .about_title {
            .wrap {
                h1{
                    font-size: 70px;
                    span{
                        font-size: 37px;
                    }
                }
                a{
                    right: 95px;
                }
            }
        }
        .content_wrap {
            p{
                padding: 0 0 0 160px;
                margin-bottom: 40px;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .hero_section{
        &:before {
            height: 490px;
        }
    }

    .our_product {
        &:before{
            width: 57.5%;
        }
        .entry_title{
            &:before {
                width: 33%;
            }
            h3.small{
                font-size:54px;
                span{
                    font-size: 70px;
                }
            }
        }
        .our_product_wrap {
            .content {
                padding: 25px;
                h5{
                    a{
                        font-size: 24px;
                    }
                }
                .find_out{
                    width: 105px;
                    height: 105px; 
                    :before{
                        width: 25px;
                        height: 25px;
                        left: -40px;
                    }
                }
            }
        }
    }

    .latest_news{
        &:after{
            width: 58.5%;
        }
        &:before{
            width: 64%;
        }
        .triangle {
            right: 34%;
        }
        .entry_title{
            &:before {
                width: 33%;
            }
            h3.small{
                font-size:54px;
                span{
                    font-size: 70px;
                }
            }
        }
    }

    .get_in_touch{
        &:before{
            width: 57.5%;
            height: 330px;
        }
        .entry_title{
            &:before {
                width: 33%;
            }
            h3.small{
                font-size:54px;
                span{
                    font-size: 70px;
                }
            }
        }
    }


}

@media screen and (max-width: 1399px) {
    .about-us{
        &:after {
            top: 108px;
            height: 470px;
        }
    }
}

@media screen and (max-width: 1360px) {
    header {
        .heder_wrap {
            .logo {
                max-width: 155px;
            }
            .navbar {
                .main_menu {
                    ul {
                        li {
                            a {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
            .social_details {
                .wrap {
                    .tell_emial {
                        p {
                            font-size: 18px;
                            a {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }

        }
    }

    .hero_section {
        .content {
            .inner_wrap {
                .left_circle {
                    width: 380px;
                    height: 380px;
                    .wrap {
                        h3{
                            font-size: 48px;
                        }
                    }
                }
            }
        }
    }

    .what_we_do {
        .wrap {
            h5{
                font-size: 40px;
            }
            ul {
                li {
                    p{
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .about-us {

        &:before{
            height: 315px;
        }
        &:after {
            top: 94px;
            height: 81%;
        }

        .about_title {
            .wrap {
                h1{
                    font-size: 60px;
                    span{
                        font-size: 32px;
                    }
                }
            }
        }
        .content_wrap {
            p{
                font-size: 19px;  
            }
        }
    }

    .our_product{
        &:before {
            width: 63%;
        }
        &:after{
            width: 61%;
        }
        .triangle{
            left: 39%;
        }
        .entry_title {
            padding: 155px 0 125px 0;
            &:before {
                width: 36%;
            }
            h3.small {
                font-size: 48px;
                span{
                    font-size: 60px;
                }
            }
        }

        .our_product_wrap {
            max-width: 85%;
            margin-bottom: 75px;
        }

        .button{
            a{
                display: inline-block;
                font-size: 20px;
            }
        }
    }

    .latest_news {
        &:after {
            width: 62%;
        }
        .triangle {
            right: 36%;
        }
        .entry_title {
            &:before {
                width: 36%;
            }
            h3.small {
                font-size: 48px;
                span{
                    font-size: 60px;
                }
            }
        }

        .news_slider {
            .latest_news_slider {
                .wrap {
                    h5.small{
                        font-size: 34px;
                    }
                    p{
                        font-size: 24px;
                    }
                    a {
                        font-size: 20px;
                        span {
                            i{
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .accreditations {
        .wrap {
            h5{
                font-size: 40px;
            }
        }
    }

    .get_in_touch {
        &:before {
            width: 55%;
            background-position: center bottom;
            bottom: 0;
            height: 83%;
        }
        .entry_title {
            h3.small {
                font-size: 48px;
                span{
                    font-size: 60px; 
                }
            }
        }
    }


    footer {
        h6.title{
            font-size: 24px;
        }
        ul{
            li {
                p {
                    font-size: 15px;
                    a{
                        font-size: 15px;
                    }
                }
            }
        }
    }

    .copyright{
        p {
            margin: 0;
        }
    }


}

@media screen and (max-width: 1330px) {
    .get_in_touch{
        &:before {
            width: 56%;
        }
    }

}


@media screen and (max-width: 1200px) {
    .our_product{
        &:after{
            width: 60%;
        }
        .triangle {
            left: 40%;
        }
    }
    .latest_news {
        &:before {
            width: 61%;
        }
        .triangle {
            right: 37.5%;
        }
    }
    .get_in_touch{
        &:before {
            width: 57%;
        }
    }
}


@media screen and (max-width: 1280px) {
    footer{
        padding: 60px 30px;  
        h6.title{
            font-size: 21px;
        }
    }
    .copyright {
        padding: 15px 0;
        p{
            font-size: 17px;
        }
    }
    .get_in_touch{
        &:before {
            width: 58%;
        }
    }
}


@media screen and (max-width: 1199px) {
    button.slick-next.slick-arrow {
        right: 0;
        width: 20px;
        height: 20px;
        &:before{
            width: 20px;
            height: 20px;
        }
    }
    button.slick-prev.slick-arrow {
        left: 0;
        width: 20px;
        height: 20px;
        &:before{
            width: 20px;
            height: 20px;
        }
    }

    header{
        .heder_wrap {


            .navbar {
                .main_menu {
                    ul{
                        li{
                            a {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            
            .social_details{
                .wrap {
                    .tell_emial{
                        p{
                            font-size: 16px;
                            a {
                                font-size: 16px;
                            }
                        }
                    }
                    .link {
                        padding-left: 30px;
                    }
                }
            }
        }
    }

    .hero_section {
        height: 765px;
        .background_img {
            img{
                height: 765px;
            }
        }

        .content {
            .inner_wrap {
                .left_circle {
                    width: 335px;
                    height: 335px;
                    .wrap {
                        h3{
                            font-size: 44px;  
                        }
                    }
                }
            }
        }
    }

    .what_we_do {
        padding: 60px 0;
        .wrap {
            h5{
                font-size: 31px;
                margin-bottom: 40px;
            }
            ul{
                flex-wrap: wrap;
            }
        }
    }

    .about-us{
        padding: 60px 0 0 0;
        &:after {
            top: auto;
            height: 82%;
            bottom: 0;
        }
        &:before{
            height: 290px;
        }
        .about-title{
            bottom: 40px;
        }
        .content_wrap{
            p {
                padding: 0 0 0 45px;
            }
        }
    }

    .our_product{
        &:before {
            width: 66%;
            background-size: contain;
        }
        &:after{
            height:417px;
        }
        .entry_title{
            &:before {
                width: 39%;
            }
        }
        .our_product_wrap {
            margin-bottom: 60px;
            max-width: 90%;
            .content {
                .find_out{
                    width: 85px;
                    height: 85px;
                    border: 10px solid #838d8e;
                    &:before{
                        left: -25px;
                        top: 20px;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
        .button {
            a{
                font-size: 18px;
                padding: 10px 15px;
            }
        }
    }


    .latest_news{
        &:after {
            width: 66%;
            background-size: contain;
        }
        .entry_title{
            padding: 145px 0 135px 0;
            &:before {
                width: 39%;
            }
        }

        .news_slider {
            .latest_news_slider {
                .wrap {
                    max-width: 85%;
                    img{
                        margin-bottom: 20px;
                    }
                    h5.small{
                        font-size: 30px;
                    }
                    p {
                        font-size: 22px;
                        padding: 0 10px 0 0;
                    }
                    a {
                        font-size: 18px;
                    }
                    p.date{
                        font-size: 18px;
                    }
                }
            }
        }
    }


    .accreditations {
        .wrap {
            h5{
                font-size: 31px;
            }
        }

        button.slick-prev.slick-arrow{
            width: 20px;
            height: 20px;
            left: -45px;

            &:before{
                width: 20px;
                height: 20px;
            }
        }
        button.slick-next.slick-arrow{
            width: 20px;
            height: 20px;
            right: -45px;
            &:before{
                width: 20px;
                height: 20px;
            }
        }
    }

    .get_in_touch{
        &:before{
            background-size: contain;
            width: 58%;
        }
        .entry_title{
            &:before {
                width: 39%;
            }
        }
    }

}

@media screen and (max-width: 1170px) {

    .hero_section {
        .hero_slide{
            &:before{
                height: 465px;
            }
        }
    }


    .about-us {
        .content_wrap {
            padding: 70px 0 30px 0;
            p{
                margin-bottom: 30px;
            }
        }
    }

    .our_product {
        &:after {
            height: 410px;
        }
        .entry_title{
            &:before {
                width: 39%;
                height: 410px;
            }
        }
    }

    .latest_news{
        &:before{
            height: 414px;
        }
        .entry_title{
            &:before {
                height: 415px;
            }
        }
    }
    
    .get_in_touch{
        &:before {
            width: 59%;
        }
    }
    
   


}   


@media screen and (max-width: 1150px) {
    .get_in_touch{
        &:before {
            width: 60%;
        }
        .triangle {
            border-top: 64px solid #ffffff;
            left: 36%;
        }
        &:after{
            width: 64%;
        }
    }
}

@media screen and (max-width: 1100px) {
    .about-us{
        &:after{
            height: 81%;
        }
        .about_title{
            .wrap {
                h1{
                    font-size: 52px;
                    padding: 0 75px 0 0;
                    span{
                        font-size: 28px;
                    }
                }
            }
        }
    }
    .our_product{
        &:after {
            height: 382px;
        }
        &:before{
            height: 321px;
        }
        .entry_title{
            padding: 135px 0 125px 0;
            &:before {
                width: 50%;
                height: 380px;
            }
            h3.small{
                font-size: 41px;
                span{
                    font-size: 52px;
                }
            }
        }
    }

    .latest_news{
        &:after{
            height: 318px; 
        }
        &:before {
            height: 383px;
        }
        .entry_title{
            padding: 162px 0 100px 0;
            &:before {
                height: 383px;
            }
            h3.small{
                font-size: 41px;
                span{
                    font-size: 52px;
                }
            }

        }
    }


    .get_in_touch {
        .entry_title {
            h3.small{
                font-size: 41px;
                span{
                    font-size: 52px;
                }
            }
        }
    }

}

@media screen and (max-width: 1060px) {

    header {
        padding: 15px 0;
    }


    .about-us{
        &:after {
            height: 80%;
            background-size: contain;
        }
        .about_title{
            .wrap {
                h1{
                    padding: 0 45px 0 0;
                }
                a{
                    right: 45px;
                    font-size: 17px;
                    padding: 5px 10px;
                }
            }
        }
        .content_wrap{
            padding: 65px 0 30px 0;
        }
    }

    .our_product{
        &:before {
            height: 290px;
        }
        &:after {
            height: 352px;
        }
        .entry_title{
            &:before {
                height: 353px;
            }
        }
    }

    .latest_news{
        &:after {
            height: 290px;
        }
        &:before {
            height: 355px;
        }
        .entry_title{
            &:before {
                height: 355px;
            }
        }
    }
    
    .accreditations{

        .wrap{
            h5{
                margin-bottom: 20px;
            }
        }

        .accreditations_slide{
            padding: 0px 25px;
        }
        button.slick-prev.slick-arrow {
            left: 0;
            width: 15px;
            height: 15px;
            &:before{
                width: 15px;
                height: 15px;
            }
        }
        button.slick-next.slick-arrow {
            right: 0;
            width: 15px;
            height: 15px;
            &:before{
                width: 15px;
                height: 15px;
            }
        }
    }



}

@media screen and (max-width: 991px) {

    body.active{
        position: fixed;
        overflow: hidden;
        width: 100%;
        &:before{
            content: ' ';
            position: absolute;
            background-color: #000000;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            z-index:5;
            opacity: 0.6;
            filter: blur(8px);
     -webkit-filter: blur(8px);
        }

        // .background{
        //     position: relative;

            
        // }
    }

    header{
        position: relative;
        .heder_wrap {
            .social_details{
                padding-right: 50px;
                .wrap {
                    .tell_emial {
                        display: flex;
                        p {
                            font-size:0;
                            padding: 0 10px;
                            a {
                                font-size:0;
                                span{
                                    display:block;
                                    i{
                                        font-size: 18px;
                                    }
                                }
                            }
                        }
                    }
                    .link{
                        padding-left: 0;
                       ul{
                        display: flex;
                        li{
                            padding: 0 10px;
                            margin: 0;
                            line-height: 1;
                            a{
                                line-height: 1;
                                height: 100%;
                                padding: 0;
                                img{
                                    width: 100%;
                                    height: 17px;
                                    margin-top: -8px;
                                }
                            }
                        }
                       }
                    }
                }
            }
            .hamburger-icon{
                display: block;
                position: absolute;
                right: 25px;
                top: 50%;
                transform: translateY(-50%);
                button{
                    height: 25px;
                    padding: 0;
                    width: 25px;
                    border: none;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background:transparent;
                    
                    
                    span.open{
                        display: block;
                        i{
                            color: #838d8e;
                        }
                    }
                    span.close{
                        display: none;
                    }
                }
                
            }
            .navbar{
                position: absolute;
                left: -300px;
                top: 0;
                height: 100vh;
                background: #fff;
                z-index: 999;
                transition: all 0.8s;
                .main_menu{
                    ul{
                        flex-direction: column;
                        padding: 10px 15px;
                        border-top: 1px solid #0000002e;
                        li{
                            padding: 15px 0 0;
                            border-bottom: 1px solid #00000057;
                            width: 100%;
                            text-align: center;

                            &:hover{
                                .mega_nav_container{
                                    ul{
                                        display: none;
                                    }
                                }
                            }

                            .mega_nav_container{
                                ul{
                                    position: relative;
                                    top: 0;
                                    left: 0;
                                    width: 90%;
                                    box-shadow: none;
                                    border: none;
                                    padding: 10px 0 0;
                                    margin: 0 auto;

                                    li{
                                        padding: 10px 0;
                                        text-align: center;
                                    }
                                    li:nth-last-child(1){
                                        border-bottom: none;
                                    }

                                }
                            }
                            .toggle_dropdown{
                                display: block;
                            }
                        }
                        li.menu-item-has-children.show{
                            .mega_nav_container{
                                display: block !important;
                                ul{
                                    display:block ;
                                }
                            }
                        }
                    }
                }
                .close {
                    display: block;
                    position: absolute;
                    top: 10px;
                    right: 15px;
                }

            }
            .navbar.active{
                left: 0;
                width: 300px;
                box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
                transition: all 0.8s;
                .main_menu{
                    width: 100%;
                    top: 0;
                    height: 100%;

                    .menu_logo {
                        display: block;
                        max-width: 60%;
                        width: 100%;
                        margin: 0 auto;
                        padding-bottom: 20px;
                        a{
                            img{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }


    .hero_section{
        .hero_slide{
            &:before{
                height: 430px;
            }
        }
    }

    .what_we_do {
        .wrap {
            ul {
                li{
                    max-width: 28%;
                    width: 100%; 
                    margin-bottom: 20px;
                }
            }
        }
    }

    .about-us{
        padding: 40px 0 0 0;
        &:before {
           display: none;
        }
        &:after {
            display: none;
        }
        .about_title{
            .wrap{
                display: flex;
                justify-content: space-between;
                align-items: center;

                h1{
                    color: $Roman_Silver;
                    padding: 0;
                    text-align: left;
                }
                a{
                    position: relative;
                    top: 0;
                    color: $Roman_Silver;
                    right: 0;
                    align-items: center;
                    display: flex;
                    padding: 8px 15px;
                    border: 1px solid;
                    height: 45px;
                    font-size: 15px;
                }
            }
        }
        .content_wrap{
            text-align: center;
            padding: 40px 0 20px;
            p{
                padding: 0;
                margin-bottom: 20px;
                color: $Roman_Silver;
                text-align: center;
                font-size: 18px;
            }
        }
    }

    .our_product{

        .our_product_wrap{
            max-width: 100%;
            margin-bottom: 40px;
            padding-top: 40px;
            .content {
                padding: 10px;
                h5.small{
                    line-height: 1;
                    a{
                        font-size: 21px;
                    }
                }
                p{
                    font-size: 16px;
                }
                .find_out {
                    width: 100%;
                    height: auto;
                    border: none;
                    position: relative;
                    left: 0;
                    background: transparent;
                    align-items: flex-start;
                    justify-content: flex-end;
                    padding-top: 10px;
                    bottom: 0;
                    a{
                        br{
                            display: none;
                        }
                    }

                    &:before{
                        display:none;
                    }
                }
            }
        }

        .entry_title {
            h3.small {
                font-size: 39px;
                span{
                    font-size: 47px;
                }
            }
        }

    }

    .latest_news {
        .entry_title {
            h3.small {
                font-size: 39px;
                span{
                    font-size: 47px;
                }
            }
        }

        .news_slider {
            .latest_news_slider {
                .wrap {
                    h5.small{
                        font-size: 27px;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 18px;
                        padding: 0;
                        padding: 0;margin-bottom: 10px;
                    }
                    a {
                        font-size: 16px;
                        span {
                            i {
                                font-size: 14px;
                            }
                        }
                    }
                    p.date {
                        font-size: 16px;
                    }
                }
            }
        }

    }

    .accreditations {
        .wrap {
            ul {
                li {
                    max-width: 17%;
                }
            }
        }
    }

    button.slick-next.slick-arrow {
        right: -25px;
    }
    button.slick-prev.slick-arrow {
        left: -25px;
    }

    footer {
        padding: 40px 15px;
        h6.title{
            font-size: 19px;
            margin: 0 0 15px 0;
        }
        ul{
            li{      
                padding: 5px 0;
            }
        }
    }

    .copyright {
        padding: 10px 0;
        p{
            font-size: 16px;
        }
    }


    .get_in_touch {
        .triangle {
            border-top: 91px solid #ffffff;
            left: 36%;
        }
    }


}

@media screen and (max-width: 950px) {
    .our_product{
        &:before {
            height: 268px;
        }
        &:after {
            height: 328px;
        }
        .entry_title{
            &:before {
                height: 328px;
            }
        }
    }

    .latest_news{
        &:before {
            height: 345px;
        }
        &:after {
            height: 280px;
        }
        .entry_title{
            &:before {
                height: 345px;
            }
        }
    }

    .get_in_touch {
        padding: 140px 0 80px 0;
    }
    
}

@media screen and (max-width: 900px) {
    .our_product{
        &:before {
            height: 256px;
        }
        &:after {
            height: 317px;
        }
        .entry_title{
            &:before {
                height: 314px;
            }
        }
    }

    .latest_news{
        &:before {
            height: 318px;
        }
        &:after {
            height: 254px;
        }
        .entry_title{
            padding: 106px 0 100px 0;
            &:before {
                height: 318px;
            }
        }
    }

    .get_in_touch {
        padding: 110px 0 80px 0;
    }

    .get_in_touch {
        .triangle {
            border-top: 72px solid #ffffff;
        }
    }
    
}
@media screen and (max-width: 870px) {
    .get_in_touch{
        .triangle {
            border-top: 86px solid #ffffff;
        }
    }
}

@media screen and (max-width: 820px) {
    .our_product{
        padding: 0 0 60px 0;
        &:before {
           display: none;
        }
        &:after {
            display: none;
        }
        .triangle {
            display: none;
         }
        .entry_title {
            padding: 40px 0 0;
            &:before {
                display: none;
            }
            h3.small{
                text-align: center;
                color: $Roman_Silver;
                text-transform: uppercase;
                br{
                    display: none;
                }
                font-size: 32px;
                span{
                    font-size: 32px;
                    color: $Roman_Silver;
                }
            }
        }
    }

    .latest_news{
        &:before {
            display: none;
         }
         &:after {
             display: none;
        }
        .triangle {
            display: none;
         }
        .entry_title {
            padding: 40px 0 0 0;
            &:before {
                display: none;
            }
            h3.small{
                text-align: center;
                color: $Roman_Silver;
                text-transform: uppercase;
                br{
                    display: none;
                }
                font-size: 32px;
                span{
                    font-size: 32px;
                    color: $Roman_Silver;
                }
            }
        }
    }

    .get_in_touch {
        padding: 105px 0 40px;
        .entry_title {
            h3.small{
                font-size: 36px;
                span{
                    font-size: 45px;
                }
            }
        }
    }

}

@media screen and (max-width: 820px) {

    .accreditations {
        .wrap {
            ul {
                li {
                    max-width: 21%;
                }
            }
        }
    }

    .about-us {
        .about_title {
            .wrap {
                h1{
                    font-size: 32px;
                    span{
                        font-size: 23px;
                    }
                }
            }
        }
    }

    .get_in_touch{
        padding: 100px 0;
        &:before{
            display: none;
        }
        &:after{
            width: 100%;
        }

        .triangle {
           display: none;
        }

        .entry_title{
            &:before{
                display: none;
            }

            h3.small{
                text-align: center;
                font-size: 35px;
                background: #0009;
                padding: 10px 0;
                text-transform: uppercase;
                br{
                    display: none;
                }
                span{
                    font-size: 35px;
                }
            }
        }
    }
}

@media screen and (max-width: 710px) {
    .hero_section {
        height: 610px;

        .hero_slide{
            &:before{
                display: none;
            }
        }   
        
        .background_img {
            img {
                height: 610px;
            }
        }

        .content{
            bottom: auto;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            .inner_wrap{
                flex-direction: column;
                align-items: center;
                .left_circle{
                    width: 100%;
                    border: 4px solid #ffffff;
                    background: #0947a473;
                    border-radius: 0;
                    height: auto;
                    .wrap {
                        width: 100%;
                        h3{
                            font-size: 33px;
                            font-weight: 500;
                            padding: 20px 0;
                            margin: 0;
                            br{
                                display: none;
                            }
                            span{
                                font-size: 38px;
                            }
                        }
                    }
                }
                .right_circle{
                    width:100%;
                    .wrap {
                        a{
                            border-radius: 0;
                            width: 90%;
                            height: auto;
                            border: 2px solid #ffffff;
                            margin: 0 auto;
                            margin-top: 10px;
                            background: #838d8eab;
                            padding: 5px;
                        }
                    }
                }
            }
        }
    }




}

@media screen and (max-width: 576px) {


    button.slick-next.slick-arrow {
        right: -5px;
    }
    button.slick-prev.slick-arrow {
        left: -5px;
    }



    .hero_section {
        height: 540px;
        .background_img {
            img {
                height: 540px;
            }
        }
        .content{
            width: 90%;
        }
    }

    .what_we_do {
        padding:20px 0 0 0;
    }
    
    .about-us{
        .about_title {
            .wrap {
                a{
                    font-size: 13px;
                    padding: 4px 8px;
                    height: 36px;
                }
            }
        }
    }


    .our_product{
        .our_product_wrap {
            max-width: 85%;
        }
    }

    footer{
        h6.title{
            font-size: 18px;
            margin: 0 0 10px 0;
        }
        ul {
            li {
                a {
                    font-size: 16px;
                }
            }
        }
    }

    .copyright {
        p {
            font-size: 14px;
        }
    }

}

@media screen and (max-width: 440px) {
    .hero_section {
        height: 435px;
        .background_img {
            img {
                height: 435px;
            }
        }
        .content {
            .inner_wrap {
                .left_circle {
                    .wrap {
                        h3{
                            font-size: 28px;
                            padding: 15px 0;
                        }
                    }
                }
            }
        }
    }

    .what_we_do {
        .wrap {
            ul {
                li {
                    max-width: 50%;
                }
            }
        }
    }

    .about-us {
        .about_title {
            .wrap{
                flex-direction: column;
                text-align: center;
                justify-content: center;


                h1{
                    text-align: center;
                    font-size: 28px;
                    margin-bottom: 15px;

                    br{
                        display: none;
                    }
                    span{
                        font-size: 28px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .our_product {
        .entry_title {
            h3.small {
                font-size: 28px;
                span{
                    font-size: 28px;
                }
            }
        }
    }

    .latest_news {
        .entry_title {
            h3.small {
                font-size: 28px;
                span{
                    font-size: 28px;
                }
            }
        }
    }


    .get_in_touch {
        .entry_title {
            h3.small {
                font-size: 28px;
                span{
                    font-size: 28px;
                }
            }
        }
    }

    .accreditations {
        .wrap {
            ul {
                justify-content: space-between !important;
                li {
                    max-width: 40%;
                }
            }
        }
    }

}

@media screen and (max-width:390px) {
    header {
        padding: 10px 0;
        .heder_wrap {
            .logo {
                max-width: 110px;
            }
            .social_details {
                .wrap {
                    .tell_emial {
                        p {
                            padding: 0 5px;
                        }
                    }
                    .link{
                        ul {
                            li {
                                padding: 0 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    .what_we_do {
        .wrap {
            ul {
                li {
                    span {
                        width: 95px;
                        height: 95px;

                        img{
                            width: 80%;
                        }
                    }
                }
            }
        }
    }
    
}

@media screen and (max-width:350px) {
    header {
        .heder_wrap {
            .navbar{
                left: -230px;
            }
            .navbar.active{
                width: 230px;
            }
        }
    }



    .our_product {
        .our_product_wrap {
            max-width: 90%;
            .content {
                h5.small{
                    a{
                        font-size: 17px;
                    }
                    p{
                        font-size: 14px;
                    }
                }
            }
        }
        .button {
            a {
                font-size: 14px;
                padding: 10px 20px;
            }
        }
    }
}






