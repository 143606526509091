footer{
	background-color: $footer;
	padding: 100px 33px 70px 23px;
	border-bottom: 1px solid $white;

	h6.title{
		font-size: 29px;
		line-height: 36px;
		font-family: $arial;
		color: $white;
		margin: 0 0 20px 0;
	}
	ul{
		li{
			padding: 10px 0;
			font-size: 17px;
			a{
				font-size: 17px;
				line-height: 1.3;
				color: $white;
				font-family: $arial;
			}
			p{
				font-size: 20px;
				line-height: 36px;
				color: $white;
				font-family: $arial;
				margin: 0;
			}
		}
	}

	.social_link{
		ul{
			display: flex;
			justify-content: flex-end;
			li{
				padding: 0 20px; 
				font-size: 20px;
				a{
					font-size: 20px;
				}
			}
		}
	}
}

.contact_us{
	ul{
		li{
			line-height: 1;
		}
	}
}

.copyright{
	padding: 20px 0;
	background-color:$footer;
	p{
		margin:0;
		font-size: 20px;
		color: $white;
		font-family: $arial;
		font-weight: 300;
	}
}